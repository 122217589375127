<template>
  <div id="destination">
    <Hero/>
    <div class="destination-section">
      <h1>{{location.name}}</h1>
      <div class="property-img" :style="{ backgroundImage: 'url(' + require('@/assets/'+location.src+'.jpg') + ')' }"></div>
      <p>{{location.bio}}</p>
      <h1>Access Points</h1>
      <p>These are some of our favorite access points for fishing {{location.name}}. Click the blue location pin on the map below for more information and directions.</p>
      <div class="access-points">
        <div>
          <h3>{{location.access_blurb}}</h3>
          <div class="access-point-border">
            <iframe :src="location.access_map" width="400" height="400"></iframe>
          </div>
        </div>
      </div>
      <h1 v-if="reports.length">Latest Reports</h1>
      <div v-if="reports.length" class="reports">
        <div class="report" v-for="report in reports" :key="report.report_id">
          <ReportCard :name="report.location_name" :flow="report.flow" :id="report.report_id" :date="report.date" :rating="report.rating" :drainage="report.drainage_name"/>
        </div>
      </div>
      <router-link v-if="reports.length" class="see-more" to="/reports">See More > </router-link>
    </div>
    <BookNow/>
  </div>
</template>

<script>
import api from '@/api'
import Hero from '@/components/Hero.vue'
import ReportCard from '@/components/ReportCard.vue'
import BookNow from '@/components/BookNow.vue'
import locations from '../data/destinations.json'
export default {
  components: { 
    Hero, 
    ReportCard,
    BookNow
  },
  data() {
    return {
      reports: [],
      location: null
    };
  },
  async created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    sort(reports) {
      return reports.sort((a, b) => new Date(b.date) - new Date(a.date));
    },
    fetchData () {
      const destinationId = this.$route.params.id
      const riverId = parseInt(this.$route.params.river_id)
      const destination = locations.find(c => c.id == destinationId)
      this.location = destination['rivers'].find(r => r.river_id === riverId)
      if (!destination || !this.location) {
        return this.$router.push('/404')
      } else {
        this.getReportsCall()
      }
    },
      async getReportsCall() {
        const getReports = await api.get(`/reports?location_id=${this.location.river_id}`)
        this.reports = this.sort(getReports.reports)
      }
  }
}
</script>


<style lang="scss">
#destination {
  .destination-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
    }
    p {
      text-align: left;
    }
    .property-img {
      height: 500px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: 10px 0;
    }
    .access-points {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      div {
        margin-top: 20px;
      }
      h3 {
        text-align: left;
      }
      .access-point-border {
        border: 3px solid black;
        padding: 6px;
      }
    }
    .report-title {
      margin-top: 50px;
    }
    .reports {
      display: flex; 
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .report {
      width: 30%;
      min-width: 300px;
    }
  }
  @media only screen and (max-width: 500px) {
    .destination-section {
      width: 90%;
      margin: auto;
      .report {
        width: 100%;
      }
    }
  }
}
</style>